@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scrollbar-width: thin;
    scrollbar-color: #0d50c4 #e2e8f0; 
  }
  
  *::-webkit-scrollbar {
    width: 8px;
  }
  
  *::-webkit-scrollbar-track {
    background: #e2e8f0;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #4a5568;
    border-radius: 50px;
    border: 3px solid #e2e8f0;
}